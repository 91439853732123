.ant-message-notice {
	&.message-error,
	&.message-success {
		.ant-message-notice-content {
			color: white;
			svg {
				fill: white;
			}
			.ant-message-custom-content {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 15px;
				padding: 0 1rem;
				span {
					user-select: none;
				}
			}
		}
	}

	&.message-error {
		.ant-message-notice-content {
			background-color: red;
		}
	}

	&.message-success {
		.ant-message-notice-content {
			background-color: #2ecc71;
		}
	}
}
