.order-items-container {
	background-color: white;
	border-radius: 10px;
	padding: 1px 10px;
}

.order-items-item {
	position: relative;
	z-index: 9999 !important;
	display: flex;
	border-radius: 5px;
	background-color: rgb(248, 248, 246);
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: 'Roboto', sans-serif !important;

	&-trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(247, 181, 0, 0.5);
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		padding: 10px 15px;
		cursor: pointer;
		transition: all 0.2s ease;

		&:hover {
			background-color: rgba(247, 181, 0, 1);
		}
	}

	&-content {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 15px;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
	}
}
